.custom-input{
    margin-top: 3px !important;
    padding: 0 10px;
    margin: 0;
    width: 100%;
    min-width: 300px;
    height: 49px;
    /* color: ${rootStyles.colors.action}; */
    font-weight: 500;
    border: 2px solid #979797;
    /* border-color: ${({ error }) => error ? "red" : rootStyles.colors.inputBorderColor}; */
    border-radius: 10px;
    transition: all 200ms ease-in-out;
}

.custom-input:focus-within {
        /* outline: none; */
        border: 2px solid #240041 !important;
        box-shadow: none;
}

.custom-input::placeholder{
      font-size: 10px;
      font-weight: 300;
}

.PhoneInputInput{
  outline: none !important;
  border: none;
}