@import "~react-image-gallery/styles/css/image-gallery.css";
*{
  font-family: 'Lato', sans-serif;
}
ul{
  padding: 0;
  list-style: none;
  margin: 0;
}

.App {
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
}

.map-container {
  height: 100%;
  width: 100%;
}
.ui.placeholder+.ui.placeholder{
  margin: 0 !important;
}
.ui.fluid.placeholder {
  width: 100%;
}

p{
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
}

.accordion-item:last-of-type {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.accordion-item:first-of-type {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    /* color: var(--bs-accordion-btn-color); */
    text-align: left;
    /* background-color: var(--bs-accordion-btn-bg); */
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  }
  .accordion-button:not(.collapsed) {
    color: black;
   z-index: 3;
    /* border-color: #7100B2 !important; */
    outline: 0;
    /* box-shadow: 0 0 0 0.15rem #7100B2 */
}
.accordion-button:focus {
    z-index: 3;
    border-color: #7100B2 !important;
    outline: 0;
    box-shadow: 0 0 0 0.15rem #7100B2
}
/* ******************* carousel ******************** */
.carousel{
  width: 100%;
}
.carousel-control-next, .carousel-control-next-icon {
  opacity: 0 !important;
  transition: all 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.carousel-control-prev, .carousel-control-prev-icon{
  opacity: 0 !important;
  transition: all 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.carousel:hover .carousel-control-next, .carousel-control-next-icon{
  opacity: 1 !important;
}
.carousel:hover .carousel-control-prev,.carousel-control-prev-icon{
  opacity: 1 !important ;
}

.carousel-control-next {
    right: 2px;;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: black !important;
    border-radius: 50px;
    background-size: 20px;
}
.carousel-indicators button{
  width: 6px !important;
  height: 6px !important;
  border-radius: 50% !important;
  /* flex: 0 !important; */
}

@media screen and (max-width: 600px) {
  .carousel-control-next, .carousel-control-next-icon {
  opacity: 1 !important;
  transition: all 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.carousel-control-prev, .carousel-control-prev-icon{
  opacity: 1 !important;
}
}

/* ********* react-image-gallery *********** */
.image-gallery-content .image-gallery-slide .image-gallery-image {
    object-fit: cover;
    max-height: calc(100vh - 80px);
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 100px;
    object-fit: cover;
}
.image-gallery-icon{
  display: none;
}

/* ********** map box ************* */
.marker {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 30px;
  height: 30px;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active-marker{
  background-color: #53088d !important;
}
.marker.active-marker .content-marker{
  background-image: url("./assets/svgs/white-location.svg");
}
.content-marker{
  background-image: url("./assets/svgs/location.svg");
  background-size: cover;
  width: 15px;
  height: 15px;
}