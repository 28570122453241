.dropdown-wrapper {
  position: relative;
  /* height: 45px; */
  width: fit-content;
  /* background-color: red; */
  display: flex;
  align-items: center;
}

/* @media screen and (min-width: 500px) {
  .dropdown-wrapper{
    display: none;
  }
} */

.trigger-button{
  padding: 10px;
  /* width: 45px; */
  height: 40px;
   background-color: #fff;
   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
   border-radius: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.drop-down {
  background: #fff;
  color: #000000;
  list-style: none;
  margin: 0;
  font-size: 18px;
  overflow: hidden;
  height: 0px;
  transition: height 0.4s ease;
  padding: 0;
  position: absolute;
  top: 50px;
  right: 0px;
  min-width: 200px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  border-radius: 10px;
  z-index: 999;
}

.drop-down.active {
  height: auto;
  padding: 20px;
  /* overflow: auto; */
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}